(function($){
    // $(".chat_on").on("click", function(){
    //     alert("click");
    //     $(".Layout").toggle();
    //     $(".chat_on").hide(300);
    // });
    "use strict";
    // Header Sticky
    $(window).on('scroll',function() {
        if ($(this).scrollTop() > 120){  
            $('.navbar-area').addClass("is-sticky");
        }
        else{
            $('.navbar-area').removeClass("is-sticky");
        }
    });
  $(".testimonials-area").hide();
    // // Testimonials Slides
     setTimeout(() => {
        $('.testimonials-slides').owlCarousel({  
            loop: true,
            nav: false,
            dots: true,
            autoplayHoverPause: true, 
            autoplay: true,
            margin: 30,
            navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
            ], 
            responsive: {
                0: {
                    items: 1,
                },
                576: {
                    items: 2,
                },
                768: {
                    items: 2,
                },
                1200: {
                    items: 2,
                }
            }
        });
         $(".testimonials-area").show();
     }, 11000);

     $(".announcement-area").hide();
     // // announcement Slides
      setTimeout(() => {
        $('.announcement-slides').owlCarousel({  
            loop: true,
            nav: false,
            dots: true,
            autoplayHoverPause: true, 
            autoplay: true,
            margin: 30,
            navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
            ], 
            responsive: {
                0: {
                    items: 1, // Display one item at a time on smaller screens
                },
                576: {
                    items: 1, // Display one item at a time on screens of width 576px and above
                },
                768: {
                    items: 1, // Display one item at a time on screens of width 768px and above
                },
                1200: {
                    items: 1, // Display one item at a time on screens of width 1200px and above
                }
            }
        });
        
          $(".announcement-area").show();
      }, 11000);
      setTimeout(() => {
        $('.announcement-slides').owlCarousel({  
            loop: true,
            nav: false,
            dots: true,
            autoplayHoverPause: true, 
            autoplay: true,
            margin: 30,
            navText: [
                "<i class='bx bx-chevron-left'></i>",
                "<i class='bx bx-chevron-right'></i>"
            ], 
            responsive: {
                0: {
                    items: 1, // Display one item at a time on smaller screens
                },
                576: {
                    items: 1, // Display one item at a time on screens of width 576px and above
                },
                768: {
                    items: 1, // Display one item at a time on screens of width 768px and above
                },
                1200: {
                    items: 1, // Display one item at a time on screens of width 1200px and above
                }
            }
        });
        
          $(".announcement-area").show();
      }, 22000);
      
    particlesJS("particles-js", { 
        particles: {
          number: { value: 150, density: { enable: true, value_area: 800 } },
          color: { value: "#7fe0fe" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 }
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#7fe0fe",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: true, mode: "push" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
      var count_particles, stats, update;
      //stats = new Stats();
      //stats.setMode(0);
      //stats.domElement.style.position = "absolute";
      //stats.domElement.style.left = "0px";
      //stats.domElement.style.top = "0px";
      //document.body.appendChild(stats.domElement);
      count_particles = document.querySelector(".js-count-particles");
      update = function () {
       // stats.begin();
        //stats.end();
        // if (window.pJSDom[0].pJS.particles && window.pJSDom[0].pJS.particles.array) {
        //   count_particles.innerText = window.pJSDom[0].pJS.particles.array.length;
        // }
        requestAnimationFrame(update);
      };
      requestAnimationFrame(update);
      
   
    // Button Hover JS
    $(function() {
        $('.default-btn')
        .on('mouseenter', function(e) {
            let parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
            $(this).find('span').css({top:relY, left:relX})
        })
        .on('mouseout', function(e) {
            let parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
            $(this).find('span').css({top:relY, left:relX})
        });
    });
    $(function() {
        $('.optional-btn')
        .on('mouseenter', function(e) {
            let parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
            $(this).find('span').css({top:relY, left:relX})
        })
        .on('mouseout', function(e) {
            let parentOffset = $(this).offset(),
            relX = e.pageX - parentOffset.left,
            relY = e.pageY - parentOffset.top;
            $(this).find('span').css({top:relY, left:relX})
        });
    });

    // Banner Content Slides
    $('.banner-content-slides').owlCarousel({ 
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        autoHeight: true,
        items: 1,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ]
    });

    // Banner Image Slides
    $('.banner-image-slides').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 2500,
        margin: 30,
        autoHeight: true,
        items: 1,
        animateOut: 'fadeOut',
    });

    // Odometer JS
    $('.odometer').appear(function(e) {
        let odo = $(".odometer");
        setTimeout(() => {
            odo.each(function() {
                let countNumber = $(this).attr("data-count");
                $(this).html(countNumber);
            });
        }, 9000);
        
    });

    // Popup Video
    $('.popup-youtube').magnificPopup({
        disableOn: 320,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    // Feedback Slides
    $('.feedback-slides').owlCarousel({ 
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    });

   

    // Tabs
    (function ($) {
        $('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
        $('.tab ul.tabs li a').on('click', function (g) {
            let tab = $(this).closest('.tab'), 
            index = $(this).closest('li').index();
            tab.find('ul.tabs > li').removeClass('current');
            $(this).closest('li').addClass('current');
            tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
            tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
            g.preventDefault();
        });
    })(jQuery);

    // Partner Slides
    $('.partner-slides').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 50,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 2,
            },
            576: {
                items: 3,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    });

   

    $(window).on ('load', function (){
        if ($(".wow").length) { 
            let wow = new WOW({
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       20,          // distance to the element when triggering the animation (default is 0)
            mobile:       true, // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
          });
          wow.init();
        }
    });

    // Go to Top
    $(function(){
        // Scroll Event
        $(window).on('scroll', function(){
            let scrolled = $(window).scrollTop();
            if (scrolled > 600) $('.go-top').addClass('active');
            if (scrolled < 600) $('.go-top').removeClass('active');
        });  
        // Click Event
        $('.go-top').on('click', function() {
            $("html, body").animate({ scrollTop: "0" },  500);
        });
    });
    
}(jQuery));